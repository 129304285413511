

<template>
  <div>
    <el-dialog
      @close="closeDialog"
      :visible="dialogFormVisible"
      width="600px"
      center>
      <el-form label-suffix=":" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="Name" prop="name" required>
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="Country" prop="country">
          <el-select v-model="ruleForm.country">
            <el-option label="Australia" value="Australia"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="closeDialog">Cancel</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ['dialogFormVisible'],
  data() {
    return  {
      ruleForm: {
        name: '',
        country: ''
      },
      rules: {
        name: [
          { required: true, trigger: 'blur' }
        ],
        country: [
          { required: true, trigger: 'blur' }
        ]
      }
    }
  },
  beforeMount () {
  },
  methods: {
    closeDialog () {
      this.ruleForm = {
        nickname: '',
        country: ''
      }
      this.$emit('update:dialogFormVisible', false)
      this.$refs['ruleForm'].resetFields()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.httpDispatch({})('addCustomer', this.ruleForm).then((res) => {
            this.closeDialog()
            this.$emit('refresh')
          }).catch((err) => {
            console.log(err)
            this.$notify.error({ title: 'Error', message: `[${err.data.code}] ${err.data.msg}`, duration: 5000 })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>